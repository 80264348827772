<template>
  <default>
    <div class="tw-mb-4 c-blue-text">
      <v-chip-group mandatory active-class=" primary">
        <v-chip active-class="false" :to="{ name: 'Dashboard' }"
          >Dashboard
        </v-chip>
        <span class="mt-2 mx-2"> > </span>
        <v-chip :to="{ name: 'AgentGuide' }">
          {{ $options.name }}
        </v-chip>
      </v-chip-group>

      <div class="tw-mt-20">
        <div class="tw-flex tw-justify-end">
          <div class="tw-w-full sm:tw-w-1/3 md:tw-w-1/4 tw-pb-3 md:tw-pb-5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </div>
        </div>
        <hr />
        <v-data-table
          :headers="headers"
          :items="proposals"
          sort-by="activePolEffectiveDate"
          :search="search"
          :sort-desc.sync="sortDesc"
          :page.sync="pagination.pageNumber"
          :items-per-page.sync="pagination.perPage"
          class="elevation-1"
          :loading="loadingTable"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 30, 40, 50],
          }"
        >
          <template v-slot:[`item.activePolSA`]="{ item }">
            <span>{{ item.activePolSA | formatNum }}</span>
          </template>
          <template v-slot:[`item.activePolPremium`]="{ item }">
            <span>{{ item.activePolPremium | formatNum }}</span>
          </template>
          <template v-slot:[`item.activePolEffectiveDate`]="{ item }">
            <span>{{ item.activePolEffectiveDate | formatToHuman }}</span>
          </template>
        </v-data-table>
      </div>
    </div>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import proposalsMixins from '@/mixins/proposalsMixins';

export default {
  name: 'Policies',
  metaInfo: {
    title: 'Policy documents',
  },
  components: {
    Default,
  },
  mixins: [proposalsMixins],
  data() {
    return {
      loadingTable: true,
      policyType: 'policy',
    };
  },
};
</script>
